// @flow strict
import React, { Suspense } from 'react';
import { useSiteMetadata } from '../../../hooks';

type Props = {
  postTitle: string,
  postSlug: string
};

const Comments = ({ postTitle, postSlug }: Props) => {
  const { url, disqusShortname } = useSiteMetadata();

  if (!disqusShortname) {
    return null;
  }

  const ReactDisqusComments = React.lazy(() => import('react-disqus-comments'));
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ReactDisqusComments
        shortname={disqusShortname}
        identifier={postTitle}
        title={postTitle}
        url={url + postSlug}
      />
    </Suspense>
  );
};

export default Comments;
